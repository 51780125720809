<template>
  <div class="chatMenu" :class="{ 'chatMenu--active': menuIsActive }">
    <nav>
      <img
        @click="logout"
        class="chatMenu__logout-img"
        src="@/assets/uploads/icons/logout-icon.svg"
        alt="выход"
      />

      <ChatAvatar
        v-if="chatList"
        :img="chatList[0]?.users.find(u => u.name.toLowerCase() === userStore.userName.toLowerCase()).avatar"
        :isCallCenter="userStore.userId == 1"
        class="chatMenu__user-profile-img"
      />
      <span>{{ userStore.userName }}</span>

    </nav>
    <div class="chatMenu__list">
      <div v-if="chatList">
        <div
        class="chatMenu__message-preview"
        :class="{
          'chatMenu__message-preview--selected': currentRoom === item.id,
        }"
        @click="$emit('selectRoom', item.id)"
        v-for="item in chatList"
        :key="item.id"
      >
        <div class="chatMenu__message-preview__img-wrapper">
          <ChatAvatar :img="item.users.find(u => u.name === item.title).avatar" size="54px" :isCallCenter="item.title === 'Клиника К+31'"/>
        </div>
        <div>
          <h6>{{ item.title }}</h6>
          <span>{{ item.messages[0]?.text }}</span>
        </div>
        <span class="chatMenu__date">{{
          formatDate(item.messages[0]?.created_at)
        }}</span>
      </div>
      </div>
      <div v-else>
        <SkeletonChatMenu v-for="i in Array.from({length: 8})" :key="i"/>
      </div>
    </div>
  </div>
</template>

<script>
import { useDateStore } from '@/stores/date'
import { useUserStore } from '@/stores/user'
import router from '@/router'
import { isValidUrl } from '@/utils/checks'
import ChatAvatar from './ChatAvatar.vue'
import SkeletonChatMenu from '@/components/common/loading/SkeletonChatMenu.vue'

export default {
  components: { ChatAvatar, SkeletonChatMenu },
  props: {
    chatList: [Object],
    currentRoom: Number,
    menuIsActive: Boolean
  },
  emits: ['selectRoom'],
  setup () {
    const userStore = useUserStore()
    const { dictMonthRod } = useDateStore()

    const logout = () => {
      window.localStorage.clear()
      router.push('/auth')
    }

    const formatDate = (dateString) => {
      if (!dateString) return ''
      const [day, month] = dateString.slice(3, 10).split('-')
      return `${day} ${dictMonthRod[parseInt(month) - 1].toLowerCase()}`
    }

    return {
      userStore,
      formatDate,
      logout,
      isValidUrl
    }
  }
}
</script>

<style scoped lang="scss">
@import '/src/assets/scss/media-mixins';

@mixin container {
  @container (max-width: 430px) {
    @content;
  }
}

$container-breakpoint: 300px;

.chatMenu--active {
  transform: translateX(0) !important;
  opacity: 1 !important;
}
.chatMenu {
  box-sizing: border-box;
  flex: 1 1 1;
  position: static;
  min-width: 428px;
  nav {
    color: #e1e8f4;
    min-height: 70px;
    padding: 16px 20px;
    background-color: #002856;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  }

  @include phone-xs {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15;
    min-width: auto;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}

.chatMenu__user-profile-img {
  margin-right: 30px;
}

.chatMenu__logout-img {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 32px;
  margin-right: 36px;
}

.chatMenu__list {
  padding: 25px 25px 80px 25px;
  background-color: #e1e8f4;
  gap: 8px;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  height: 95vh;

  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.chatMenu__list::-webkit-scrollbar {
  display: none;
}
.chatMenu__message-preview {
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: start;
  border-bottom: 1px solid #bac7de;
  padding: 8px;

  div {
    display: flex;
    flex-direction: column;
    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #002856;
      margin: 0;

      max-width: 270px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include phone-xs {
        max-width: 190px !important;
        font-size: 16px;
      }

    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #7f8da9;

      max-width: 290px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include phone-xs {
        max-width: 190px !important;
        font-size: 12px;
      }
    }
  }
}
.chatMenu__message-preview--selected {
  background-color: #ffffff;
}
.chatMenu__date {
  margin-left: auto;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.69px;
  text-align: right;
  text-wrap: nowrap;
  color: #7f8da9;

  @include phone-xs {
    font-size: 10px;
    display: block !important;
  }

  @include container {
    display: none;
  }
}

.chatMenu__message-preview__img-wrapper {
  width: 54px;
  height: 54px;

  margin-right: 18px;
}
</style>
