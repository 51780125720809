<template>
  <div class="resize-handler"></div>
</template>

<script></script>

<style lang="scss" scoped>
@import "/src/assets/scss/media-mixins";
.resize-handler {
  width: 4px;
  padding: 0;
  cursor: ew-resize;
  flex: 0 0 auto;

  @include phone-xs {
    display: none;
  }
}

.resize-handler::before {
  content: '';
  display: block;
  height: 100%;
  background: #002856;
  margin: 0 auto;
}
</style>
