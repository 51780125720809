<template>
  <div class="chat-window__wrapper">
    <nav class="chat-window__nav">
      <ChatAvatar
        v-if="partnerName"
        :img="partnerAvatar"
        :isCallCenter="partnerName === 'Клиника К+31'"
        class="chat-window-nav__user-profile-img"
      />
      <div v-if="partnerName">
        <span>Пациент</span>
        <span>{{ partnerName }}</span>
      </div>
      <!-- <img src="@/assets/uploads/magnifiying-glass-icon.svg" alt="поиск" /> -->
      <img
        @click.prevent="$emit('openMenu')"
        src="@/assets/uploads/icons/chat-icon.svg"
        alt="поиск"
        class="chat-window__nav__chats-img"
      />
    </nav>

    <div class="chat-widnow">
      <div class="chat-room">
        <TheLoader v-if="loading" class="chat-loader" />

        <div v-if="!currentRoom" class="chat-widnow__empty">
          <WomanDoctor text="Выберите чат" />
        </div>

        <MessagesList
          v-if="currentRoom && !loading"
          :messages="messages"
          :partnerAvatar="partnerAvatar"
          :chatLoading="chatLoading"
          :chatPadding="chatPadding"
          :allMessagesLoaded="true"
          :userName="userName"
          :error="error"
          @loadMoreMessages="loadMoreMessages"
        />

        <ChatInput
          :isDoctorChat="false"
          :roomId="currentRoom"
          :clientId="clientId"
          @applyError="applyError"
          @updateChatPadding="updateChatPadding"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MessagesList from '@/components/chat/MessagesList.vue'
import ChatInput from '@/components/chat/ChatInput.vue'
import TheLoader from '@/components/common/loading/TheLoader.vue'
import WomanDoctor from './WomanDoctor.vue'
import { ref } from 'vue'
import ChatAvatar from './ChatAvatar.vue'
import { useUserStore } from '@/stores/user'

export default {
  name: 'ChatCallCenterView',
  components: {
    MessagesList,
    ChatInput,
    TheLoader,
    WomanDoctor,
    ChatAvatar
  },
  emits: ['getChatsData', 'addRoomToList', 'openMenu'],
  props: {
    loading: Boolean,
    userName: String,
    clientId: String,
    currentRoom: [Number, null],
    messages: [Array, null],
    partnerName: String,
    partnerAvatar: {
      type: String,
      required: false,
      default: null
    }
  },
  setup () {
    const error = ref(null)
    const chatLoading = ref(false)
    const chatPadding = ref(117)
    const userStore = useUserStore()

    const applyError = (err) => {
      error.value = err
    }

    const updateChatPadding = (newHeignt) => {
      chatPadding.value = newHeignt
    }

    const loadMoreMessages = () => {
      chatLoading.value = true
      setTimeout(() => {
        chatLoading.value = false
      }, 2500)
    }

    return {
      loadMoreMessages,
      chatLoading,
      chatPadding,
      updateChatPadding,
      error,
      applyError,
      userStore
    }
  }
}
</script>

<style scoped lang="scss">
@import '/src/assets/scss/media-mixins';
.chat-window__wrapper {
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.chat-window__nav {
  box-sizing: border-box;
  color: #e1e8f4;
  min-height: 70px;
  padding: 16px 20px;
  background-color: #002856;
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    span:nth-of-type(1) {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #f1842c;

      @include phone-xs {
        font-size: 12px;
      }
    }
    span:nth-of-type(2) {
      font-size: 18px;
      font-weight: 700;
      text-align: left;

      @include phone-xs {
        font-size: 14px;
      }
    }
  }

  // img:nth-of-type(2) {
  //   margin-left: auto;
  //   width: 20px;
  //   height: 20px;
  // }
}

.chat-widnow {
}
.chat-widnow__empty {
  height: calc(100vh - 122px);
  font-size: 32px;
  margin-top: 300px;
  text-align: center;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 12px 0;
  z-index: 10;
}

.chat-room {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  overflow-y: hidden;
  background: #fff;
  z-index: 10;
  @include desktop {
    height: calc(100vh - 108px);
  }

  @include phone-xs {
    height: calc(100vh - 60px);
  }
}

.chat-room__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  padding-top: 12px;
  h3 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.chat-room__navigation {
  padding-bottom: 16px;
}

.section-title__back {
  cursor: pointer;
}

.chat-window-nav__user-profile-img {
  margin-right: 30px;
}

.chat-loader {
  height: 100%;
}

.chat-window__nav__chats-img {
  display: none;
  @include phone-xs {
    display: block;
    min-width: 32px;
    height: 32px;
    margin-left: auto;
  }
}
</style>
