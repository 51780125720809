import { defineStore } from 'pinia'
import { ref, watchEffect } from 'vue'

export const useUserStore = defineStore('user', () => {

  const userToken = ref(null)
  const userId = ref(null)
  const userName = ref(null)

  const setUserToken = (token) => {
    window.localStorage.setItem('user_token', token)
    userToken.value = token
  }
  const setUserId = (id) => {
    window.localStorage.setItem('user_id', id)
    userId.value = id
  }
  const setUserName = (name) => {
    window.localStorage.setItem('user_name', name)
    userName.value = name
  }

  watchEffect(() => {
    console.log('userToken', userToken.value)
    console.log('userId', userId.value)
    console.log('userName', userName.value)
  })
  return {
    userToken,
    userId,
    userName,
    setUserToken,
    setUserId,
    setUserName
  }
})
