<script>
import '@/assets/normalize.css'
import { RouterView } from 'vue-router'
import { useUserStore } from './stores/user'
import { onMounted } from 'vue'

export default {
  components: { RouterView },
  setup () {
    const userStore = useUserStore()

    onMounted (() => {
      userStore.userToken = window.localStorage.getItem('user_token')
      userStore.userId = window.localStorage.getItem('user_id')
      userStore.userName = window.localStorage.getItem('user_name')
    })
  }
}
</script>

<template>
  <div class="wrapper">
    <notifications position="top left"/>
    <RouterView />
  </div>
</template>

<style lang="scss">

@import "/src/assets/scss/fonts";

html, body {
  height: 100vh;
  font-family: "Circe", Circe, sans-serif;
}

#app {
  height: 100vh;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
</style>
