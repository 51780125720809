<template>
  <div class="chat-input">
    <div class="chat-input__upload-wrapper">
      <label for="chat-input__upload-btn" class="chat-input__upload-btn">
      </label>
      <input
        id="chat-input__upload-btn"
        type="file"
        multiple
        @change="onFilesChange"
        :disabled="!roomId"
      />
      <div class="chat-input__upload-count" v-show="files.length"> {{ files.length }}</div>
    </div>
    <textarea
      class="chat-input__input-field"
      :class="{ 'chat-input__input-field--shrunk': userInput || files.length}"
      :disabled="!roomId"
      v-model="userInput"
      ref="textarea"
      placeholder="Сообщение"
      @keydown.enter="postMessage"
    ></textarea>
    <button
      @click.prevent="postMessage"
      class="chat-input__send-btn"
      :class="{ 'chat-input__send-btn--active': userInput || files.length }"
    >
      <img
        src="@/assets/uploads/icons/paper-plane-icon.svg"
        alt="отправить сообщение"
      />
    </button>
  </div>
</template>

<script>
import axios from 'axios'
import { ref, toRefs, watch } from 'vue'

export default {
  name: 'ChatInput',
  components: {},
  emits: ['updateChatPadding', 'applyError'],
  props: {
    isDoctorChat: Boolean,
    roomId: Number,
    clientId: {
      type: [String, null],
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const { roomId, clientId } = toRefs(props)
    const files = ref([])
    const userInput = ref('')
    const textarea = ref(null)
    const adjustTextarea = () => {
      textarea.value.style.height = 'auto'
      textarea.value.style.height = `${textarea.value.scrollHeight - 12}px`
      const textareaHeight = parseInt(textarea.value.style.height)

      if (textareaHeight === 40) {
        emit('updateChatPadding', 117)
      } else if (textareaHeight === 58) {
        emit('updateChatPadding', 137)
      } else if (textareaHeight >= 76) {
        emit('updateChatPadding', 157)
      }
    }

    const onFilesChange = (e) => {
      if (e.target.files.length > 5) {
        alert('Можно добавить максимум 5 файлов')
        e.preventDefault()
      }
      files.value = Array.from(e.target.files).slice(0, 5)

    }

    const postMessage = async (event) => {
      if (event.key === 'Enter') event.preventDefault()
      const savedMsg = userInput.value
      const savedFiles = files.value
      userInput.value = ''
      files.value = []
      textarea.value.focus()

      const formData = new FormData()

      formData.append('room_id', roomId.value)
      formData.append('client', clientId.value)
      formData.append('message', savedMsg)

      savedFiles.forEach((file, index) => {
        formData.append(`file[${index}]`, file, file.name)
      })
      try {
        const response = await axios.post(
          'https://k31centr.wfst.ru/api/chat/message',
          formData,
          {
            headers: {
              'Content-type': 'multipart/form-data'
            }
          }
        )
        if (response.status !== 200) {
          emit('applyError', response.data.message)
          userInput.value = savedMsg
          files.value = savedFiles
        } else {
          textarea.value.style.height = 40 + 'px'
          emit('updateChatPadding', 117)
        }
      } catch (err) {
        emit('applyError', err.message)
        userInput.value = savedMsg
        files.value = savedFiles
      }
    }

    watch(userInput, () => {
      adjustTextarea()
    })

    return {
      postMessage,
      onFilesChange,
      userInput,
      textarea,
      files
    }
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/scss/media-mixins";
.chat-input {
  position: fixed;
  height: fit-content;
  box-shadow: 0 -4px 12px 0 #00000014;
  background-color: #e1e8f4;
  padding: 16px 18px;
  display: flex;
  align-items: center;
  gap: 16px;
  @include desktop {
    bottom: 9px;
    position: sticky;
  }

  @include tablets-md {
    bottom: 0;
    width: -webkit-fill-available;
  }
}

.chat-input__upload-wrapper {
  width: 20px;
  height: 20px;
  position: relative;
}

#chat-input__upload-btn {
  display: none;
}

.chat-input__upload-btn {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/uploads/icons/paperclip-icon.svg');
}

.chat-input__upload-count {
  position: absolute;
  right: -5px;
  top: 70%;
  width: 14px;
  height: 14px;
  background-color: #f1842c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: baseline;

  font-family: Open Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #FFF;
}

.chat-input__send-btn {
  all: unset;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  right: 18px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #002856;
  opacity: 0;
  transform: scale(0);
  transition: all 0.1s ease-in, animation 0.4s ease-in, opacity 0.2s ease, scale 0.4s ease;

  img {
    margin-left: 8px;
  }

  &:active {
    transform: scale(90%);
  }
}

.chat-input__send-btn--active {
  opacity: 1;
  transform: scale(100%);
  animation: bounce-in 0.4s;
}

.chat-input__input-field {
  display: block;
  overflow: hidden;
  resize: none;
  height: 40px;
  max-height: 80px;
  flex-grow: 1;
  max-width: 100%;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #bac7de;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;

  transition: all 0.2s ease-in-out;
}

.chat-input__input-field--shrunk {
  max-width: calc(100% - 110px);
}

.chat-input__input-field::placeholder {
  font-family: Circe;
  font-size: 14px;
  font-weight: 350;
  line-height: 14px;
  text-align: left;
  color: #7f8da9;
  padding-top: 4px;
}

.chat-input__input-field:focus {
  outline: none;
  border: 1px solid #002856;
}

.chat-input__upload-img {
}

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  opacity: 0;
  transform: scale(0);
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
