<template>
  <div ref="chatWrapper" class="chat-wrapper">
    <ChatMenu
      ref="menu"
      :chatList="chatList"
      :currentRoom="currentRoom"
      :menuIsActive="menuIsActive"
      @selectRoom="selectRoom"
    />

    <ResizeHandler ref="resizeHandler"/>

    <ChatWindow
      :currentRoom="currentRoom"
      :clientId="clientId"
      :messages="processedMessages"
      :partnerName="partnerName"
      :partnerAvatar="partnerAvatar"
      :loading="loading"
      :userName="userStore.userName"
      @getChatsData="getChatsData"
      @addRoomToList="addRoomToList"
      @openMenu="menuIsActive = true"
    />
  </div>
</template>

<script>
import ChatMenu from '@/components/chat/ChatMenu.vue'
import ChatWindow from '@/components/chat/ChatWindow.vue'
import ResizeHandler from '@/components/chat/ResizeHandler.vue'
import axios from 'axios'
import { Centrifuge } from 'centrifuge'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useUserStore } from '@/stores/user'

export default {
  components: { ChatMenu, ChatWindow, ResizeHandler },
  setup () {
    const menuIsActive = ref(false)
    const loading = ref(null)
    const error = ref(null)
    const clientId = ref(null)
    const chatsData = ref(null)
    const currentRoom = ref(null)
    const userStore = useUserStore()
    const client = ref(null)
    const subscribedRooms = ref([])
    const messages = ref([])
    const room = ref(null)
    const menu = ref(null)
    const resizeHandler = ref(null)
    const chatWrapper = ref(null)
    const isHandlerDragging = ref(null)

    const launchWindowDrag = () => {
      window.addEventListener('mousedown', function (e) {
        if (e.target === resizeHandler.value.$el) {
          isHandlerDragging.value = true
        }
      })

      window.addEventListener('mousemove', function (e) {
        if (!isHandlerDragging.value) {
          return false
        }

        var containerOffsetLeft = chatWrapper.value.offsetLeft
        
        var pointerRelativeXpos = e.clientX - containerOffsetLeft
  
        var boxAminWidth = 416 // min width of the menu block 

        menu.value.$el.style.width = (Math.max(boxAminWidth, pointerRelativeXpos - 8)) + 'px'
        menu.value.$el.style.flexGrow = 0
       
      })

      window.addEventListener('mouseup', function () {
        isHandlerDragging.value = false
      })
    }

    const partnerName = computed(() => {
      if (currentRoom.value) {
        return chatList.value.find(chat => chat.id === currentRoom.value).title
      } else {
        return null
      }
    })

    const partnerAvatar = computed(() => {
      if (currentRoom.value) {
        return chatList.value.find(chat => chat.id === currentRoom.value).users.find(u => u.name.toLowerCase() !== userStore.userName.toLowerCase()).avatar
      } else {
        return null
      }
    })

    const processedMessages = computed(() => {
      let lastDate = null
      const processedMessages = []
      const reversedMessages = messages.value.toSorted((a, b) => a.id - b.id).toReversed()
      const tempDayMessages = []
      const walkedIds = new Set()

      reversedMessages.forEach((message) => {
        if (walkedIds.has(message.id)) {
          return
        }
        walkedIds.add(message.id)

        const messageDate = message.created_at.slice(0, 10)

        if (messageDate !== lastDate) {
          if (tempDayMessages.length > 0) {
            processedMessages.push(...tempDayMessages)
            processedMessages.push({
              type: 'date',
              date: lastDate,
              id: `date-${lastDate}`
            })
            tempDayMessages.length = 0
          }
          lastDate = messageDate
        }

        tempDayMessages.push({
          type: 'message',
          ...message
        })
      })

      if (tempDayMessages.length > 0) {
        processedMessages.push(...tempDayMessages)
        processedMessages.push({
          type: 'date',
          date: lastDate,
          id: `date-${lastDate}`
        })
      }

      return processedMessages
    })

    const chatList = computed(() => {
      if (chatsData.value) {
        const hasMessages = chatsData.value.filter(c => c.messages[0])
        const noMessages = chatsData.value.filter(c => !c.messages[0])
        const chatsSorted = hasMessages.toSorted((a, b) => {
          const dateA = new Date(a.messages[0].created_at)
          const dateB = new Date(b.messages[0].created_at)
          return dateB.getTime() - dateA.getTime()
        })

        return chatsSorted.concat(noMessages)
      } else {
        return null
      }
    })

    const getWsClientId = async () => {
      return new Promise((resolve) => {
        client.value = new Centrifuge(
          'wss://k31centr.wfst.ru/connection/websocket',
          {
            token: userStore.userToken
          }
        )
        client.value.connect()

        client.value.on('connected', (ctx) => {
          console.log('CONNECTED!', ctx)
          clientId.value = ctx.client
          resolve(true)
        })

        client.value.on('error', (error) => {
          console.log(error)
        })
      })
    }

    const connectChatUser = async () => {
      return new Promise((resolve) => {
        const chatUser = client.value.newSubscription(
          `doctor:${userStore.userId}`
        )

        chatUser.subscribe()

        chatUser.on('subscribed', () => {
          console.log('subscribed to doctor N', userStore.userId)
          resolve(true)
        })
      })
    }

    const getChatsData = async () => {
      const params = new URLSearchParams({
        client: clientId.value
      })
      try {
        const response = await axios.get(
          `https://k31centr.wfst.ru/api/my?${params}`
        )
        if (response.data.status === 'ok') {
          chatsData.value = response.data.data.rooms
        } else {
          console.log(response.data.message)
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }

    const fetchChatsData = async () => {
      await getWsClientId()
      await connectChatUser()
      await getChatsData()
    }

    const subscribeToRoom = async () => {
      return new Promise((resolve) => {
        room.value = client.value.newSubscription(`room:${currentRoom.value}`)
        room.value.subscribe()
        room.value.on('subscribed', () => {
          console.log('subscribed to room', currentRoom.value)
          subscribedRooms.value.push(currentRoom.value)
        })
        room.value.on('publication', async (ctx) => {
          pushNewMessage(ctx.data.message)
          await getChatsData()
          resolve(true)
        })
      })
    }

    const getCurrentRoomMessages = async () => {
      error.value = null
      const params = new URLSearchParams({
        client: clientId.value,
        id: currentRoom.value
      }).toString()

      try {
        const response = await axios.get(
          `https://k31centr.wfst.ru/api/chat/room?${params}`
        )
        if (response.data.status === 'ok') {
          messages.value = response.data.data.messages
        } else {
          error.value = response.data.message
        }
      } catch (err) {
        error.value = err
      }
    }

    watch(currentRoom, async () => {
      if (currentRoom.value) {
        loading.value = true
        await getCurrentRoomMessages()
        loading.value = false

        if (!subscribedRooms.value.includes(currentRoom.value)) {
          await subscribeToRoom()
        }

      }
    })

    const selectRoom = (room) => {
      menuIsActive.value = false 
      currentRoom.value = room
    }

    const addRoomToList = (room) => {
      subscribedRooms.value.push(room)
    }

    const pushNewMessage = (message) => {
      messages.value.push(message)
    }

    watchEffect(() => {
      console.log('current room', currentRoom.value)
      console.log('chat list', chatList.value)
    })

    onMounted(async () => {
      await fetchChatsData()
      launchWindowDrag()
    })

    return {
      clientId,
      client,
      chatList,
      loading,
      getChatsData,
      currentRoom,
      selectRoom,
      subscribedRooms,
      addRoomToList,
      messages,
      userStore,
      partnerName,
      partnerAvatar,
      processedMessages,
      menu,
      resizeHandler,
      chatWrapper,
      menuIsActive
    }
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/scss/media-mixins";
.chat-wrapper {
  position: relative;
  width: 80%;
  border: 1px solid #bac7de;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  height: 95vh;

  @include tablets-md {
    width: 100%;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
