<template>
  <div class="skeleton__chat-menu">
    <div></div>
    <div></div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.skeleton__chat-menu {
  height: 71px;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #bac7de;

  display: flex;
  align-items: center;

  div:nth-child(1) {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background: linear-gradient(90deg, rgba(208, 208, 208, 0.667) 40%, #efefefaa, rgba(208, 208, 208, 0.667) 60%) right / 300% 100%;
    margin-right: 18px;
    animation: skeleton-loading 1.5s linear infinite;
  }
  div:nth-child(2) {
    background: linear-gradient(90deg, rgba(208, 208, 208, 0.667) 40%, #efefefaa, rgba(208, 208, 208, 0.667) 60%) right / 300% 100%;
    width: calc(100% - 72px);
    height: 54px;
    border-radius: 10px;
    animation: skeleton-loading 1.5s linear infinite;
  }
}

@keyframes skeleton-loading {
  to {
    background-position: left;
  }
}
</style>
