<template>
  <div class="chat-room__user-message">
    <div v-html="underlineLinks(text)"></div>
    <!-- <div v-if="isValidUrl(item.text)">КАРТИНКА ССЫЛКИ</div> -->
    <div>
      <div
        class="chat-room__user-message__download"
        v-for="file in attach"
        :key="file.id"
      >
        <a :href="file.url" target="_blank"
          ><img src="@/assets/uploads/icons/download-icon.svg" alt="скачать"
        /></a>

        <div>
          <a :href="file.url" target="_blank">{{ file.name }}</a>
          <div>
            <!-- <span>31 MB -</span> -->
            <a :href="file.url" target="_blank">Download</a>
          </div>
        </div>
      </div>
    </div>
    <div>{{ formatTime(createdAt) }}</div>
  </div>
</template>

<script>

export default {
  props: {
    text: String,
    attach: {
      type: Array,
      required: false,
      default: null
    },
    createdAt: String
  },
  setup () {
    const formatTime = (datetime) => {
      const date = new Date(datetime)
      return date.toTimeString().slice(0, 5)
    }

    const underlineLinks = (text) => {
      const urlRegex = /https?:\/\/[^\s]+/g

      return text.replace(
        urlRegex,
        (url) =>
          `<a style="text-decoration: underline" href="${url}" target="_blank">${url}</a>`
      )
    }

    return {
      formatTime,
      underlineLinks
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-room__user-message {
  align-self: end;
  max-width: 360px;
  margin-left: 21px;
  display: flex;
  gap: 4;
  flex-direction: column;
  background-color: #e1e8f4;
  border-radius: 16px 16px 0 16px;
  padding: 12px 16px 8px 16px;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  > div:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 4px;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  > div:nth-child(3) {
    color: #bac7de;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    text-align: right;
  }
}

.chat-room__user-message__download {
  display: flex;
  flex-direction: row;
  gap: 16px;

  img {
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    > a {
      text-decoration: none;
      color: #002856;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;
      &:hover {
        text-decoration: underline;
      }
    }

    div {
      align-items: flex-end;
      display: flex;
      // span:first-child {
      //   margin-right: 5px;
      //   font-size: 14px;
      //   font-weight: 400;
      //   line-height: 18px;
      //   text-align: left;
      //   color: #7f8da9;
      // }
      a:last-child {
        text-decoration: none;
        color: #002856;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
      }
    }
  }
}
</style>
