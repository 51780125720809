<template>
  <div class="auth-modal">
    <div class="auth-modal__window">
      <h1>Авторизация</h1>
      <form @submit.prevent="submit" :class="{ error: isError }">
        <div>
          <label>Логин</label>
          <input v-model="userInfo.login" type="phone" />
        </div>
        <div>
          <label>Пароль</label>
          <input v-model="userInfo.password" type="password" />
        </div>
        <button>
          <span v-if="isLoading" class="loader"></span>
          <span v-else>ВОЙТИ</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { reactive, ref, watch } from 'vue'
import { useUserStore } from '@/stores/user'
import router from '@/router'
import { showError } from '@/utils/showError'

export default {
  setup () {
    const userStore = useUserStore()
    const { setUserToken, setUserId, setUserName } = userStore
    const isError = ref(false)
    const isLoading = ref(false)
    const userInfo = reactive({
      login: null,
      password: null
    })

    const submit = async () => {
      isLoading.value = true
      const token = window.btoa(`${userInfo.login}:${userInfo.password}`)
      try {
        const response = await axios.get(
          'https://lk.k31.ru/api/v1/chat/auth/doctor',
          {
            headers: {
              Authorization: `Basic ${token}`
            }
          }
        )
        if (response.data.status === 'ok') {
          setUserToken(response.data.data.token)
          setUserId(response.data.data.user_id)
          let name =
            response.data.data.user_id === 1
              ? 'Клиника К+31'
              : response.data.data.last_name +
                ' ' +
                response.data.data.first_name +
                ' ' +
                response.data.data.middle_name
          setUserName(name)
          router.push('/')
        } else {
          isError.value = true
          showError('Ошибка авторизации', response.data.message)
        }
      } catch (err) {
        isError.value = true
        showError('Ошибка авторизации', err.response.data.message)
      } finally {
        isLoading.value = false
      }
    }

    watch(userInfo, () => {
      isError.value = false
    })

    return {
      isError,
      isLoading,
      userInfo,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #00285680;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-modal__window {
  margin: 0 30px 0 30px;
  padding: 20px 60px 20px 60px;
  width: 300px;
  height: 400px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #002856;
    margin-top: 0;
    margin-bottom: 50px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  div {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  label {
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
    color: #002856;
    margin-bottom: 3px;
  }

  input {
    height: 40px;
    border-radius: 8px;
    border: 1px #bac7de solid;
    padding: 0 10px 0 10px;
  }

  button {
    height: 44px;
    width: 100%;
    border-radius: 30px;
    border: none;
    background-color: #bac7de;
    cursor: pointer;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    letter-spacing: 1px;

    &:hover {
      background-color: #bbceef;
    }
  }
}

.error {
  color: rgb(199, 30, 30);
  input {
    background-color: #ffd3d3bc;
    border-color: rgb(199, 30, 30);
  }
}

.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
