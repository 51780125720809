import { createRouter, createWebHistory } from 'vue-router'
import ChatView from '../views/ChatView.vue'
import AuthorizationView from '@/views/AuthorizationView.vue'
import { useUserStore } from '@/stores/user'



const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: ChatView,
      beforeEnter: (to, from, next) => {
        const userStore = useUserStore()
        if (!userStore.userToken) {
          router.push('/auth') 
        }
        next()
      }
    }, 
    {
      path: '/auth',
      name: 'auth',
      component: AuthorizationView
    }
  ]
})

export default router
