import { useNotification } from '@kyvg/vue3-notification'

const notification = useNotification()

export const showError = (title, text) => {
  notification.notify({
    title,
    text,
    type: 'error',
    duration: 5000
  })
}