<template>
  <div
    :style="{
      paddingBottom: isSubscribtionOver ? 0 : chatPadding + 'px',
      scrollBehavior: smoothScroll ? 'smooth' : 'auto',
    }"
    class="chat-room__chat-wrapper container"
    ref="chatWrapper"
  >
    <ErrorText
      v-if="error"
      :showBtn="false"
      :error="error"
      :textAlign="'center'"
    />
    <TransitionGroup v-if="!error && messages.length" name="messagesList">
      <template v-for="item in messages" :key="item.id">
        <DateMessage v-if="item.type === 'date'" :date="item.date" />

        <UserMessage
          v-if="
            item.type === 'message' &&
            item.name.toLowerCase() === userName.toLowerCase()
          "
          :text="item.text"
          :attach="item.attach"
          :createdAt="item.created_at"
        />

        <PartnerMessage
          v-if="
            item.type === 'message' &&
            item.name.toLowerCase() !== userName.toLowerCase()
          "
          :text="item.text"
          :attach="item.attach"
          :createdAt="item.created_at"
          :partnerImg="partnerAvatar"
        />
      </template>
    </TransitionGroup>
    <WomanDoctor v-if="messages.length === 0" text="Вы можете начать диалог"/>
    <TheLoader v-if="chatLoading" />
  </div>
</template>

<script>
import ErrorText from '@/components/common/ErrorText.vue'
import TheLoader from '@/components/common/loading/TheLoader.vue'
import DateMessage from '@/components/chat/messages/DateMessage.vue'
import UserMessage from '@/components/chat/messages/UserMessage.vue'
import PartnerMessage from '@/components/chat/messages/PartnerMessage.vue'
import { onDeactivated, ref, toRefs, watch, watchEffect } from 'vue'
import WomanDoctor from './WomanDoctor.vue'

export default {
  name: 'MessagesList',
  components: {
    TheLoader,
    ErrorText,
    DateMessage,
    UserMessage,
    PartnerMessage,
    WomanDoctor
  },
  emits: ['loadMoreMessages'],
  props: {
    messages: [Object],
    isDoctorChat: Boolean,
    partnerAvatar: {
      type: String,
      required: false,
      default: null
    },
    userName: String,
    roomData: {
      type: Object,
      required: false,
      default: null
    },
    error: [Object, null],
    chatLoading: Boolean,
    chatPadding: Number,
    isSubscribtionOver: Boolean,
    allMessagesLoaded: Boolean
  },
  setup (props, { emit }) {
    const chatWrapper = ref(null)
    const smoothScroll = ref(false)
    const { chatPadding, chatLoading, messages } = toRefs(props)

    const rateSelected = (score) => {
      emit('openModal', score)
    }

    const scrollToBottom = async () => {
      // console.log('SCROLL TO BOTTOM....')
      chatWrapper.value.scrollTop = 0
    }

    const handleScroll = () => {
      // console.log('scroll listener working, SCROLL TOP', (+chatWrapper.value.scrollTop))
      // console.log('put against', chatWrapper.value.scrollHeight - chatWrapper.value.clientHeight)
      // console.log('window inner heigt', chatWrapper.value.clientHeight)
      if (
        Math.abs(chatWrapper.value.scrollTop - 10) >=
        chatWrapper.value.scrollHeight - chatWrapper.value.clientHeight
      ) {
        if (!props.allMessagesLoaded) emit('loadMoreMessages')
      }
    }

    watch(chatPadding, async () => {
      await scrollToBottom()
      smoothScroll.value = true
    })

    watch(chatWrapper, async () => {
      if (chatWrapper.value) {
        chatWrapper.value.addEventListener('scroll', handleScroll)
      }
    })

    watch(messages, async (newMessages, oldMessages) => {
      if (oldMessages) {
        if (newMessages.length - oldMessages.length === 1) {
          await scrollToBottom()
        }
      }
    })

    watch(chatLoading, async () => {
      if (chatLoading.value === false) {
        // +114 - размер TheLoader
      }
    })

    onDeactivated(() => {
      chatWrapper.value.removeEventListener('scroll', handleScroll)
    })

    watchEffect(() => {
      console.log('messages', messages.value)
    })

    return {
      chatWrapper,
      smoothScroll,
      rateSelected
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/main.scss';

.chat-room__chat-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  color: #002856;
  overflow-y: scroll;
  height: 100%;
  padding-left: 30px;
  padding-top: 14px;
  padding-right: 20px;
  @include desktop {
    padding-bottom: 16px !important;
  }
  @include phone-xs {
    padding-left: 20px;
  }
}

.chat-room__chat-wrapper::-webkit-scrollbar {
  display: none;
}

.chat-room__chat-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.messagesList-enter-active:not(.chat-room__date),
.messagesList-leave-active:not(.chat-room__date) {
}
.messagesList-enter-from:not(.chat-room__date),
.messagesList-leave-to:not(.chat-room__date) {
}
</style>
