<template>
  <div class="chat-woman-doctor">
    <div class="chat-woman-doctor__header">
      <img
        class="chat-woman-doctor__header-woman"
        src="@/assets/uploads/images/doctor-woman.svg"
      />
      <img
        class="chat-woman-doctor__header-background"
        src="@/assets/uploads/images/background.svg"
      />
    </div>
    <div class="chat-woman-doctor__text">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
.chat-woman-doctor {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40%;
}
.chat-woman-doctor__header {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 32px;
}

.chat-woman-doctor__header-background {
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  z-index: 0;
}

.chat-woman-doctor__header-woman {
  z-index: 1;
}

.chat-woman-doctor__text {
  display: flex;
  flex-direction: column;
  align-items: center;

  span:first-child {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 6px;
  }
}
</style>
