<template>
      <img v-if="isValidUrl(img)" :src="img" alt="фото профиля" :class="{contained: isCallCenter}"/>
      <img v-else-if="img" :src="getIconUrl(img)" alt="фото профиля" />
      <img v-else src="@/assets/uploads/icons/man.svg" alt="фото профиля"/>
</template>

<script>
import { isValidUrl } from '@/utils/checks'

export default {
  props: {
    size: {
      type: String,
      default: '38px',
      required: false
    },
    img: String,
    isCallCenter: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup () {
    const getIconUrl = (name) => new URL(`/src/assets/uploads/icons/${name}.svg`, import.meta.url).href
    return { isValidUrl, getIconUrl }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: v-bind(size);
  height: v-bind(size);
  background-color: #e0fee3;
  border-radius: 100%;
  object-fit: cover;
}

.contained { 
  object-fit: contain;
  background-color: #ffffff;
}
</style>